import api from '.'

export default {
  all() {
    return api.get('/rotas')
  },

  master(rotaId: string, startDate: string, endDate: string, gradeId: number | null) {
    let queryString = `/rotas/${rotaId}/master?start_date=${startDate}&end_date=${endDate}`

    if (gradeId) {
      queryString += `&user_grade_id=${gradeId}`
    } else {
      queryString += `&only_shifts=1`
    }

    return api.get(queryString)
  },

  masterByDate(rotaId: string, dateId: string) {
    return api.get(`/rotas/${rotaId}/date/${dateId}/master`)
  },

  weeklyShifts(rotaId: string) {
    return api.get(`/rotas/${rotaId}/shifts/weekly`)
  },

  weeklyRequirements(rotaId: string) {
    return api.get(`/rotas/${rotaId}/requirements/weekly`)
  },

  suggestions(rotaId: string, dateId: string, shiftId: string) {
    return api.get(`/rotas/${rotaId}/date/${dateId}/shift/${shiftId}/suggestions`)
  },

  board(rotaId: number, date: string) {
    return api.get(`/rotas/${rotaId}/board?date=${date}`)
  },
}